/* eslint-disable import/no-cycle */
import Parse from 'parse';
import moment from 'moment';
import { setPaymentMethods, setTransactions } from './paymentCapture';

import { dumpDocumentLinks } from '../utils/documentDump';
import { showSuccessAlert, hideAlert } from './alert';
import { handleError } from './auth';
import { showLoader, hideLoader } from './loading';

export const initPaymentRequest = (paymentRquestData) => async (dispatch) => {
  dispatch({ type: 'SEND_PAYMENT_REQUEST', paymentRquestData });
  try {
    await Parse.Cloud.run('initPaymentRequest', { ...paymentRquestData });
    dispatch(
      showSuccessAlert({
        title: 'Email Sent',
        message: `Payment request sent to ${paymentRquestData.toEmail}`,
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getValidOffices = () => async (dispatch, getState) => {
  dispatch({ type: 'GET_VALID_OFFICES' });
  const { auth } = getState();
  const { company } = auth;
  const validOffices = await company.getValidPaymentRequestOffices();
  dispatch({ type: 'SET_VALID_OFFICES', validOffices });
};

export const getPaymentRequestDataFromToken = (tokenId) => async (dispatch) => {
  dispatch({ type: 'GET_PAYMENT_REQUEST_DATA_FROM_TOKEN', tokenId });
  const response = await Parse.Cloud.run('getPaymentRequestDataFromToken', {
    tokenId,
  });
  return response;
};

export const processPaymentRequest = (params) => async (dispatch) => {
  dispatch({ type: 'PROCCESS_PAYMENT_REQUEST', params });
  dispatch(showLoader('Processing Payment Request ...'));
  try {
    const response = await Parse.Cloud.run('processPaymentRequest', params);
    return response;
  } finally {
    dispatch(hideLoader());
  }
};

const getEstimateData = (estimate) => {
  const createdBy =
    estimate && estimate.get('createdBy')
      ? {
          nameFirst: estimate.get('createdBy').get('nameFirst'),
          nameLast: estimate.get('createdBy').get('nameLast'),
        }
      : {
          nameFirst: 'n/a',
          nameLast: 'n/a',
        };

  const estimateData = estimate
    ? {
        createdAt: estimate.get('createdAt'),
        createdBy: {
          ...createdBy,
        },
        leadResults: estimate.get('leadResults'),
        office: {
          name: estimate.get('office').get('name'),
        },
      }
    : {
        createdAt: new Date(),
        createdBy: {
          ...createdBy,
        },
        leadResults: [],
        office: {
          name: 'n/a',
        },
      };

  return estimateData;
};

const buildEstimateDetails = (customer) => {
  const estimate = customer.get('estimate');
  const estimateData = getEstimateData(estimate);
  return {
    objectId: customer.id,
    apiSourceData: customer.get('apiSourceData'),
    addresses: customer.get('addresses'),
    contacts: customer.get('contacts'),
    estimateName: customer.get('estimateName'),
    nameFirst: customer.get('nameFirst'),
    nameLast: customer.get('nameLast'),
    estimateObject: estimate,
    estimate: {
      ...estimateData,
    },
    createdBy: customer.get('createdBy'),
    spouse: customer.get('spouse'),
  };
};

export const startFetchTransactionsFromPaymentMethods = (
  paymentMethods,
) => async (dispatch) => {
  try {
    if (!paymentMethods.length) {
      dispatch(setTransactions([]));
      return;
    }
    const transactionQueries = paymentMethods.map((paymenetMethod) => {
      const tranactionRelation = paymenetMethod.relation(
        'transactionsRelation',
      );
      return tranactionRelation.query();
    });
    const transactionOrQuery = Parse.Query.or(...transactionQueries);
    transactionOrQuery.select([
      'paymentMethod',
      'amount',
      'type',
      'description',
      'createdBy.nameFirst',
      'createdBy.nameLast',
      'token',
      'response',
    ]);
    transactionOrQuery.descending('createdAt');
    const transactions = await transactionOrQuery.find();
    dispatch(setTransactions(transactions));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startFetchPaymentsForCustomer = (customer) => async (dispatch) => {
  try {
    const paymentMethodsQuery = new Parse.Query('PaymentMethod');
    paymentMethodsQuery.equalTo('company', customer.get('company'));
    paymentMethodsQuery.equalTo('customer', customer);
    paymentMethodsQuery.descending('createdAt');
    const paymentMethods = await paymentMethodsQuery.find();
    dispatch(setPaymentMethods(paymentMethods));
    dispatch(startFetchTransactionsFromPaymentMethods(paymentMethods));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startSubmitTransaction = (params, callback) => async (
  dispatch,
) => {
  try {
    dispatch(showLoader('Processing Transaction ...'));
    const allowedTypes = ['purchase', 'authorize', 'general_credit'];
    if (!allowedTypes.includes(params.transactionType)) {
      return;
    }
    const {
      transactionType,
      amount,
      zip,
      description,
      paymentMethod,
      customerId,
    } = params;
    const transactionParams = {
      transactionType,
      paymentMethod: paymentMethod.objectId,
      paymentToken: paymentMethod.token,
      amount: parseInt(`${amount}`, 10),
      zipCode: zip,
      description,
    };
    const result = await Parse.Cloud.run(
      'spreedlyPaymentTransaction',
      transactionParams,
    );
    const customer = new Parse.Object('SSCustomer');
    customer.id = customerId;
    await dispatch(startFetchPaymentsForCustomer(customer));
    callback(result);
    dispatch(
      showSuccessAlert({
        title: 'New Transaction',
        message: result,
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

const getLogObjectsForCustomer = async (customer) => {
  const logObjectQuery = new Parse.Query('LogObject');
  logObjectQuery.equalTo('relatedCustomerIds', customer.get('localObjectId'));
  logObjectQuery.include('createdBy');
  const logObjects = [];
  await logObjectQuery.each(async (logObject) => {
    const createdBy = logObject.get('createdBy');
    logObjects.push({
      id: logObject.id,
      createdBy: createdBy
        ? `${createdBy.get('nameFirst')} ${createdBy.get('nameLast')}`
        : 'N/A',
      date: moment(logObject.get('date')).format('LLL'),
      logFileURL: logObject.get('logs').url(),
    });
  });
  return logObjects;
};

export const getEstimateDetails = (id) => async (dispatch, getState) => {
  dispatch(showLoader('Loading Estimate Details...'));
  const { auth } = getState();
  const { company } = auth;
  const validPaymentCaptureOffices = await company.getValidPaymentCaptureOffices();
  const query = new Parse.Query('SSCustomer');
  query.equalTo('objectId', id);
  query.include([
    'estimate',
    'estimate.createdBy',
    'estimate.documents',
    'addresses',
    'contacts',
    'spouse',
  ]);
  const customer = await query.first();
  const analyticObjectQuery = new Parse.Query('AnalyticObject');
  analyticObjectQuery.equalTo('estimate', customer.get('estimate'));
  const analyticsObject = await analyticObjectQuery.first();
  let analyticsData = {};
  if (analyticsObject) {
    analyticsData = {
      contractSent: analyticsObject.get('contractSent'),
      creditAppSubmitted: analyticsObject.get('creditAppSubmitted'),
      isSale: analyticsObject.get('isSale'),
      usedFinanceOption: analyticsObject.get('usedFinanceOption'),
      startTime: analyticsObject.get('startTime'),
      apptDuration: analyticsObject.get('apptDuration'),
      onTime: analyticsObject.get('onTime'),
      logObjects: analyticsObject.get('logObjects'),
      spousePresent: analyticsObject.get('spousePresent'),
    };
  }

  const logObjects = await getLogObjectsForCustomer(customer);

  const estimateDetails = buildEstimateDetails(customer);
  const details = {
    ...estimateDetails,
    analyticsData,
    validPaymentCaptureOffices,
    logObjects,
  };
  dispatch(startFetchPaymentsForCustomer(customer));
  dispatch({ type: 'SET_ESTIMATE_DETAILS', details });
  dispatch(hideLoader());
};

export const getCustomersByName = async (props) => {
  const { customerFilter: searchText, limit, skip } = props;
  const items = await Parse.Cloud.run('searchCustomersByName', {
    searchText,
    limit,
    skip,
    select: ['objectId'],
  });
  return items;
};

export const setEstimatesDateFilter = (params) => async (dispatch) =>
  dispatch({
    type: 'SET_ESTIMATES_DATE_FILTER',
    params: { ...params, skip: 0 },
  });

export const setEstimatesFilter = (params) => async (dispatch) =>
  dispatch({
    type: 'SET_ESTIMATES_FILTER',
    params: { ...params, skip: params.skip || 0 },
  });

export const initOfficeSelection = () => async (dispatch, getState) => {
  const {
    auth: { offices },
    estimatesPage,
  } = getState();
  const { skip, selectedOffices } = estimatesPage;
  if (
    (!selectedOffices || !selectedOffices.length) &&
    offices &&
    offices.length
  ) {
    dispatch(
      setEstimatesFilter({
        skip: skip || 0,
        selectedOffices: offices.map((office) => office.id),
      }),
    );
  }
};

export const getEstimates = () => async (dispatch, getState) => {
  dispatch(showLoader('Fetching Estimates...'));

  const {
    estimatesPage,
    auth: { offices },
  } = getState();

  const {
    startDate,
    endDate,
    userFilter = [],
    customerFilter,
    selectedOffices = [],
    limit,
    skip,
  } = estimatesPage;

  const params = {
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
    userFilter,
    customerFilter,
    selectedOffices: selectedOffices.length
      ? selectedOffices
      : offices.map(({ id }) => id),
    limit,
    skip,
  };

  try {
    const items = await Parse.Cloud.run('queryEstimates', { ...params });
    dispatch({ type: 'SET_ESTIMATES', items });
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(hideLoader());
  }
};

export const downloadAllDocuments = (estimate) => async (
  dispatch,
  getState,
) => {
  const { auth } = getState();
  const { allMainMenuOptions = [] } = auth;
  await dumpDocumentLinks({ estimate, mainMenuOptions: allMainMenuOptions });
  return dispatch({ type: 'DOWNLOAD_ALL_DOCS', estimate });
};
